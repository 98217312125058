.chat-receiver {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 0 20px;
  // height: 25px;
  // margin: 15px 0;
  .chat-to {
    margin-right: 5px;
    color: #8E8E8E;
    font-size: 12px;
  }
  
}

.chat-receiver-item{
  padding-left: 30px;
  &.selected {
    padding-left: 10px;
  }
  .chat-receiver-item-affix{
    color:#999;
    margin-left: 5px;
  }
}
.chat-privilege-item {
  padding-left: 25px;
  &.selected {
    padding-left: 5px;
  }
}
.chat-receiver-button{
  border-radius: 8px;
  border-color: #0071A9;
  span{
    color: #0071A9;
    font-size: 12px;
  }
}