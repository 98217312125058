.video-mask-setting-dialog {
  .video-preview{
    width: 520px;
    height: 292px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }
  .video-preview-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .video-clip{
    position: absolute;
    width: 208px;
    height: 208px;
    top:0;
    left: 0;
    &:hover {
      border: 1px solid #4477CE;
      cursor: move;
    }
  }
  .video-background-list {
    border: none;
    display: flex;
    & .video-background-item{
      width: 120px;
      height: 68px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #333;
      color: #f5f5f5;
      font-size: 14px;
      font-weight: 700;
      margin-right: 5px;
      padding: 0;
      overflow: hidden;
      & .ant-menu-title-content {
        width: 120px;
        height: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      & img{
        width: 100%;
        max-height: 100%;
      }
      &__active {
        border: 1px solid #F86F03;
      }
    }
  }
}