.chat-message-item{
  display: flex;
  justify-content:space-between ;
  // margin-bottom: 15px;
  padding-top: 10px;
  gap: 8px;
  &.myself{
    flex-direction: row;
    .chat-message-avatar {
      margin-right: 5px;
     // margin-left: 16px;
    }
  }
  .chat-message-avatar {
    width: 34px;
    height: 34px;
    color: #333;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    .chat-message-avatar-img {
      width: 26px;
      height: 26px;
      border-radius: 6px;
    }
  } 
  .chat-message-content{
    flex-grow: 1;
    width: calc(100% - 42px);
    .chat-message-info{
      display: flex;
      //justify-content: space-between;
      align-items: center;
      &.myself{
        flex-direction: row;
        .chat-message-receiver {
          text-align:left;
        }
      }
      .chat-message-receiver {
        //flex-grow: 1;
        text-align: left;
        margin-right: 5px;
        font-size: 10px;
        font-weight: 400;
        margin-bottom: unset;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        >span {
          margin:0 5px;
          color: #ABB5BE;
        }
        >a {
          text-decoration: none;
          color: #2da5ff;
        }
      }
      .chat-message-time {
        max-width: 100px;
        color: #ABB5BE;
        font-size: 10px;
        font-weight: 400;
        margin-bottom: unset;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .noWrap-oneLine {
        max-width: calc(100% - 115px);
        font-size: 14px;
        color: #152536;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
        margin-bottom: unset;
      }
    }
    ul{
      padding-left: 0;
    }
    .chat-message-text-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // width: calc(100% - 20px);
      .chat-message-text {
        // padding: 8px 10px;
        // border-radius: 8px;
        // background: rgba(82,82,128,0.09);
        color: #68717A;
        font-size: 14px;
        // margin-bottom: 8px;
      }
      &.myself {
        align-items: flex-start;
        padding-left: 0;
        // .chat-message-text {
        //   background: #deedfd;
        // }
      }

    }
  }
}