.time-slots {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.headingText{
    font-size: 1rem;
    color: #000000;
    font-weight: 700;
}

.time-slots-container{
    max-height: 16rem;
    height: calc(100% - 80px);
    overflow-y: auto;
    padding: 0 20px;
}

.custom-calendar{
    display: flex;
    justify-content: center;
}

.date-Icon-Div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
}

.react-datepicker__current-month,
.react-datepicker__navigation,
.react-datepicker__aria-live{
    display: none !important;
}

.LeftRightIcon{
    height: 21px !important;
    width: 21px !important;
    cursor: pointer;
}

.noteText{
    font-size: 13px;
    font-style: italic;
    margin-bottom: 0;
}

.react-datepicker{
    border: 0;
}

.react-datepicker__header{
    border: 0;
    background-color: #ffffff;
}

.react-datepicker__time-container{
    border: 0;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled, 
.react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc !important;
    cursor: default !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today, 
.react-datepicker__year-text--today{
    color: #000000 !important;
    font-weight: 700 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    font-size: 16px;
    font-weight: 600;
    color: #68717A;
    display: inline-block;
    width: 2rem;
    line-height: 2rem;
    text-align: center;
    margin: 2px;
    border-radius: 50%;
}

.react-datepicker__day{
    cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover{
    background-color: #E7F2F6;
    color: #0071A9 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.slotsHeading{
    color: #484848;
    font-weight: 600;
    font-size: 1rem;
}

.react-datepicker__current-month{
    margin-bottom: 1rem;
}

.react-datepicker__navigation{
    top: 9px;
}

.react-datepicker__navigation--previous {
    right: 20px !important;
}

.react-datepicker__year-read-view--down-arrow, 
.react-datepicker__month-read-view--down-arrow, 
.react-datepicker__month-year-read-view--down-arrow, 
.react-datepicker__navigation-icon::before{
    color: #212121;
}

.react-datepicker__year-read-view--down-arrow, 
.react-datepicker__month-read-view--down-arrow, 
.react-datepicker__month-year-read-view--down-arrow, 
.react-datepicker__navigation-icon::before {
    border-color: #7e7e7e;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 1rem;
    position: absolute;
    top: 6px;
    width: 1rem;
}

.react-datepicker__day--selected:hover, 
.react-datepicker__day--in-selecting-range:hover, 
.react-datepicker__day--in-range:hover, 
.react-datepicker__month-text--selected:hover, 
.react-datepicker__month-text--in-selecting-range:hover, 
.react-datepicker__month-text--in-range:hover, 
.react-datepicker__quarter-text--selected:hover, 
.react-datepicker__quarter-text--in-selecting-range:hover, 
.react-datepicker__quarter-text--in-range:hover, 
.react-datepicker__year-text--selected:hover, 
.react-datepicker__year-text--in-selecting-range:hover, 
.react-datepicker__year-text--in-range:hover {
    background-color: #0071A9;
    color: #FFFFFF !important;
}

.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range {
    background-color: #0071A9 !important;
    color: #FFFFFF !important;
    font-weight: 700;
}

.react-datepicker__day--keyboard-selected:hover, 
.react-datepicker__month-text--keyboard-selected:hover, 
.react-datepicker__quarter-text--keyboard-selected:hover, 
.react-datepicker__year-text--keyboard-selected:hover{
    background-color: #E7F2F6;
    color: #0071A9 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected{
    background-color: transparent;
    color: #000000;
    font-weight: 700;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    width: auto;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #ffffff;
    color: black;
    font-weight: bold;
    border: 1px solid;
    border-radius: 5px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: auto;
    padding: 7px 10px;
    white-space: nowrap;
}

.slotsCheckBtn{
    display: none;
}

.checkedBoxs {
    text-align: center;
    width: 100%;
    border: 1px solid #DEE2E6;
    border-radius: 8px;
    padding: 14px 0;
    margin-top: .5rem;
    font-size: 14px;
    cursor:pointer;
    font-weight: 700;
    color: #68717A;
}

.checkedBoxs.Active {
    border: 1px solid #152536 !important;
    color: #152536 !important;
}

@media (min-width: 576px) {
    .custom-calendar{
        display: inline-block;
    }
    
    .availability-calendar-container {
        display: flex;
        height: 23rem;
        max-height: 26rem;
        border-top: 1px solid #EFF0F4;
        border-bottom: 1px solid #EFF0F4;
    }

    .calendar-wrapper{
        width: 60%;
        border-right: 1px solid #EFF0F4;
    }
    
    .time-slots {
        width: 40%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .time-slots-container{
        max-height: 100%;
    }
}

@media (min-width: 768px) {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        margin: 4px;
    }
}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}

@media (min-width: 1400px) {}
