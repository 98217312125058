@font-face {
  font-family: 'Open Sans';
  src: url('../src/assets/font/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  min-height: 100vh;
}

.selectdrop.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #fecba1 !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25) !important;
}

.editprofile fieldset {
  top: 0px !important;
}
.educationDetails .MuiInputBase-input {
  font-weight: 600 !important;
}
.educationDetails .MuiOutlinedInput-input {
  font-weight: 600 !important;
}

table td {
  white-space: nowrap;
}

.MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}
/* hobbies  */
.hobbies-field .MuiAutocomplete-input {
  padding: 9px !important;
}
.hobbies-field .MuiOutlinedInput-notchedOutline {
  top: -5px !important;
}

.informationtext .MuiTypography-root {
  font-family: "Open Sans";
  text-align: center !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #152536 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #000 !important;
}
/* for org settings */
.MuiRadio-root.MuiRadio-colorPrimary.Mui-checked {
  color: #000 !important;
}

.MuiDivider-root {
  border: 1px solid #c9cbd2 !important;
}

.selectGroup .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
  box-shadow: none !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #fecba1 !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25) !important;
}

.profile-celender .MuiOutlinedInput-input {
  text-transform: uppercase !important;
}

.profile-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

legend {
  height: 0px !important;
  border: none !important;
}

.user-found-text td:last-child {
  padding: 16px;
  text-align: left !important;
}

td:last-child {
  text-align: right !important;
}
th:last-child {
  text-align: right !important;
}

.MuiFormHelperText-contained {
  margin-left: 0px !important;
}
Button {
  white-space: nowrap;
}

/* Edit profile phone number box css */
.editPhonePrefix .MuiOutlinedInput-root {
  padding-right: 1rem !important;
}
/* Add member Autocomplete */
.countryHeight .MuiInputBase-colorPrimary {
  padding: 0px !important;
  padding-left: 6px !important;
}

.countryHeight .MuiAutocomplete-endAdornment {
  right: 1px !important;
}

/* Scrollbar design*/
::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background: #CED4DA;
  border-radius: 3rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #ABB5BE;
}

/* Scrollbar design End*/
.btnBackGround:hover {
  background-color: rgba(25, 118, 210, 0.9) !important;
}

.organization-add {
  color: #152536 !important;
  font-family: Open Sans;
  font-size: 22px !important;
  font-weight: 600 !important;
  text-align: center !important;
}
.graduation-date .MuiOutlinedInput-root {
  padding: 0px !important;
}

.phone-img {
  padding-left: 6px;
}
/* popup button code */
.dialog-btn {
  font-family: Open Sans;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  border-radius: 8px;
  height: 45px;
}
.dialog-bg-btn {
  color: #fff !important;
  background: #152536 !important;
  width: 560px;
}
.dialog-border-btn {
  color: #68717a !important;
  border: 1px solid #68717a !important;
  background: #fff !important;
  width: 560px;
}
.community-text {
  font-family: Open Sans !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

/* edit-profile-calendar */
.MuiDateCalendar-root {
  overflow:hidden auto !important;
  width: 280px !important;
}
.MuiYearCalendar-root {
width: 280px !important; 
height: 160px !important
}
.MuiMonthCalendar-root {
width: 280px !important;
}
.selectGroup .MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}
.match-details-text {
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
