@import './spacing.scss';
@import './variables.scss';
@import './extrastyle.scss';

.htmlContent {
    color: $primary;
    word-wrap: break-word;
    font-size: 14px !important;
    line-height: 1.5 !important;
    font-family: Open Sans !important;

    blockquote {
        padding-left: 10px;
        border-left: solid 3px #ABB5BE;
    }

    p,
    blockquote {
        line-height: 1.5 !important;
        font-size: 14px !important;
        margin-bottom: 0px !important;
        font-family: Open Sans !important;

        span,
        strong {
            font-size: 14px !important;
            line-height: 1.5 !important;
            font-family: Open Sans !important;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $primary;
        font-size: 14px !important;
        line-height: 1.5 !important;
        font-family: Open Sans !important;

        span {
            color: $primary;
            font-size: 14px !important;
            line-height: 1.5 !important;
            font-family: Open Sans !important;
        }
    }

    span,
    strong {
        font-size: 14px !important;
        line-height: 1.5 !important;
        font-family: Open Sans !important;
    }

    code {
        color: $primary;
        font-size: 14px !important;
        line-height: 1.5 !important;
        font-family: monospace !important;

        strong {
            font-size: 14px !important;
            line-height: 1.5 !important;
            font-family: monospace !important;
        }
    }

    img {
        max-height: 100% !important;
        max-width: 100% !important;
        display: flex;
        margin: 0px auto;
    }

    iframe {
        border: 0;
        width: 100%;
        display: flex;
        cursor: pointer;
        border-radius: 8px;
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
        aspect-ratio: 1.81;

        /* Fallback for older browsers */
        // height: calc(100% / 1.81); 
        // height: auto;
    }

    ul,
    ol {
        margin: 1rem 0;
        font-size: 14px !important;
        line-height: 1.5 !important;
        font-family: Open Sans !important;
    }

    ul,
    ol{
        margin: 1rem 0;
    }
    
    ul li {
        list-style-type: disc;
        font-size: 14px !important;
        line-height: 1.5 !important;
        font-family: Open Sans !important;
    }

    ol li {
        list-style-type: decimal;
        font-size: 14px !important;
        line-height: 1.5 !important;
        font-family: Open Sans !important;
    }
}

@media (min-width: 1200px) {
    .htmlContent {
        iframe {
            height: 315px;
            width: 560px;
        }
    }
}