@import './variables.scss';

.community-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}

.communityRight-header {
  display: flex;
  align-items: center;
}
.header-img {
  padding: 0;
  margin: -10px 0 10px 20px;
  width: 34px;
  height: 34px;
  cursor: pointer;
}
.innerHeader {
  display: flex;
  column-gap: 1rem;
  background-color: #ffffff;
}
.community-tab-text {
  color: $secondary;
  //color: #df6438;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 0px 20px 20px;
  cursor: pointer;
}
.community-tab-text.active {
  border-bottom: 3px solid #df6438;
  //color: $primary !important;
  color: #df6438 !important;
  cursor: default !important;
}

.favorites-text {
  cursor: pointer;
  color: #152536;
}
.communityCardSec {
  height: calc(100% - 200px);
  overflow: auto;
  padding: 16px;
}

.cardBg-img {
  position: relative;
  border-radius: 8px;
  // height: 262px;
  aspect-ratio: 0.7634;
  // background: linear-gradient(180deg, rgba(38, 39, 56, 0) 53.24%, #262738 81.49%);
  background-position: center !important;
  background-size: cover !important;
  cursor: pointer;
}
.cardBg-img img.card-image {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  z-index: 0;
  object-fit: cover;
}
.card-image-BG {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(38, 39, 56, 0) 53.24%, #262738 81.49%);
}
.cardMemberName {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardMemberBody {
  color: #adb5bd !important;
}
.member-text {
  color: #fff !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.dialog-btn-sec {
  justify-content: center;
  padding: 20px 24px 20px !important;
  margin-bottom: 10px;
}

/** Community Member */
.CommunityMember-bodyBox {
  display: flex;
  height: calc(100% - 85px);
  // overflow: hidden;
}
.favorites {
  font-size: 16px;
  font-weight: 600 !important;
  cursor: pointer !important;
  color: #68717a !important;
}

// .community-member-sec {
//   display: flex;
//   height: 90%;
// }

.communityMember-leftside {
  flex: 0 0 100%;
  background: #fff;
  height: 100%;
  border-right: 1px solid #eff0f4;
  display: none;
}
.communityMember-main {
  flex: 1;
  margin: 20px;
  overflow-y: auto;
}
.ProfileImageVideoSize {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 190px;
  cursor: pointer;
  border-radius: 8px;
  object-fit: cover;
  aspect-ratio: 1.81;
}
.CommunityProfileBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  padding: 20px;
  gap: 8px;
  .ProfileCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}
.User-list {
  overflow-y: auto;
  height: calc(100% - 120px);
  width: 100%;
}
.MemberTabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eff0f4;
}
.MemberTabs .divText {
  width: 50%;
  text-align: center;
  color: $secondary;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.MemberTabs .divText.active {
  color: $primary;
  border-bottom: 3px solid #df6438;
}

@media (min-width: 600px) {
  .communityMember-leftside {
    flex: 0 0 350px;
    display: block;
  }
  .communityMember-main {
    flex: 1;
    margin: 20px;
    overflow-y: auto;
  }
  .ProfileImageVideoSize {
    width: 560px;
    height: 310px;
  }
  .CommunityProfileBox {
    flex-direction: row;
    align-items: center;
    .ProfileCard {
      align-items: center;
      flex-direction: row;
    }
  }
}
