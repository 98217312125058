@import './variables.scss';

/************************************************************/

/** Common for all pages */
.public-DraftStyleDefault-block {
    margin: 0px !important;
}

.public-DraftEditor-content {
    div {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        span {
            font-family: Open Sans !important;
            font-size: 14px !important;
            line-height: 1.5 !important;
        }
    }
}

.wrapperClassName {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
}

.helpEditorClass,
.taskEditorClass,
.contentSettingEditorClass {
    word-wrap: break-word;

    figure {
        margin: 0 !important;
    }
}

/** Chat page only */
.chatEditor {
    .public-DraftStyleDefault-block {
        span {
            background-color: white !important;
        }
    }
}

/** Chat and add content pages editor size */
.editorSize,
.chatEditorClass {
    padding: 10px;
    min-height: 60px;
    max-height: 200px;
    font-size: 14px;
}

/** Task editor size */
.taskEditorClass {
    padding: 10px;
    min-height: 100px;
    max-height: 250px;
    font-size: 14px;
}

/** Chat */
.chatTextBox {
    position: relative;
    border: 1px solid $chatBorder;
    margin: .5rem .75rem 0;
    border-radius: 8px;
}

.iconBorder {
    border: none !important;
    padding: 0 !important;
    background-color: $chatToolbarColor !important;
}

.iconBorder:active {
    opacity: 1;
}

.iconBorder img {
    opacity: .5;
}

.iconBorder:hover img {
    opacity: 1;
}

.rdw-option-active img {
    opacity: 1;
}

.iconBorder:hover,
.rdw-option-active,
.rdw-option-wrapper:hover,
.rdw-option-wrapper:active {
    box-shadow: none !important;
    background-color: transparent !important;
}

.rdw-option-wrapper {
    border: none !important;
    background-color: transparent !important;
}

/** Icons size*/
.myCustomEmoji img {
    width: 16px;
    height: 16px;
}

.myCustombold {
    height: 12px;
    width: 10px;
}

.emoji_class {
    top: -210px !important;
}

.toolBarStyle {
    border: none !important;
    background-color: $chatToolbarColor !important;
    padding: 10px 0 !important;
    margin-bottom: 0 !important;
    border-radius: 0px 0px 8px 0px !important;
}

.rdw-editor-toolbar div {
    margin-bottom: 0;
}

.rdw-inline-wrapper {
    border-left: 1px solid $chatBorder;
    border-right: 1px solid $chatBorder;
}

.DraftEditor-editorContainer {
    color: #152536;
    font-size: 14px;
}

.custom-modal .rdw-emoji-modal,
.custom-modal .rdw-link-modal,
.custom-modal .rdw-image-modal {
    top: 0 !important;
    transform: translateY(-100%) !important;
    left: -200px;
}

.rdw-dropdown-optionwrapper {
    max-height: 200px;
    bottom: 198px !important;
}

/** Task page editor */
.toolBarStyleTask {
    .tasktoolbar-modal {
        top: unset !important;
        position: fixed !important;
        bottom: 15% !important;
        left: 80% !important;
        transform: translate(-50%, -50%) !important;
    }

    .rdw-embedded-modal.taskEmbedded-modal {
        height: auto !important;
        top: unset !important;
        bottom: 0px;

        .rdw-embedded-modal-size {
            display: none !important;
        }

        .rdw-embedded-modal-header .rdw-embedded-modal-header-option {
            width: 100% !important;
            align-items: flex-start;
        }
    }

    .height-auto {
        height: auto !important
    }
}

/** Article page editor */
.toolBarStyleArticle {
    .articletoolbar-modal {
        top: unset !important;
        position: fixed !important;
        bottom: 10% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

    .height-auto {
        height: auto !important
    }

    .rdw-embedded-modal.articletoolbar-modal {
        height: auto !important;

        .rdw-embedded-modal-header .rdw-embedded-modal-header-option {
            width: 100% !important;
            align-items: flex-start;
        }

        .rdw-embedded-modal-size {
            display: none !important;
        }
    }
}