@import './spacing.scss';
@import './variables.scss';
@import './extrastyle.scss';

/** Color */
.color-gray-light,
.secondary-light {
  color: $gray3 !important;
}

.color-info,
.color-blue {
  color: $blue1 !important;
}

.CED4DA {
  color: #ced4da !important;
}

.color-primary {
  color: $primary !important;
}

.color-secondary {
  color: $secondary !important;
}

.color-white,
.color-FFF {
  color: white !important;
}

.color-212529 {
  color: #212529 !important;
}

.color-golden {
  color: $golden !important;
}

/** disabled */
.disabled-color {
  color: #EFF0F4 !important;
}

.disabled-bg {
  background-color: #EFF0F4 !important;
}

/** Font sizes */
@each $size in $font-sizes {
  .fs-#{$size} {
    font-size: #{$size}px !important;
  }
}

/** Font weight */
@each $weight in $font-weights {
  .fw-#{$weight} {
    font-weight: #{$weight} !important;
  }
}

/** */
.d-inline-flex {
  display: inline-flex !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

/* If we use this class on mobile view we are not showing*/
.d-desktop,
.d-none {
  display: none;
}

.color-gray-light,
.secondary-light {
  color: $gray3 !important;
}

.bodyBox {
  padding: 20px;
  overflow: auto;
  // align-items: center;
  // align-content: center;
  height: calc(100% - 85px);
}

.flex-column-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}

a {
  text-decoration: none !important;
}

ul.listStyle-dots,
ul.listStyle-disc {
  li {
    list-style: disc !important;
  }
}

ul.listStyle-alpha {
  li {
    list-style: lower-alpha !important;
  }
}

.removetext {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
}

.mainBody {
  // height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  background-color: $bg;

  .LeftSideNav {
    background-color: $dosenNavyBlue;
    min-width: 100%;
    max-width: 100%;
    // height: 90px;
    height: 64px;
    z-index: 1;
    // New
    // position: fixed;
    // bottom: 0;

    .sideBarMain {
      height: 100%;
      width: 100%;
      overflow: hidden;

      .logoArea {
        display: none;
        text-align: center;
        padding-top: 25px;
        margin-bottom: 45px;
      }

      .sideBarTabsBox {
        overflow-x: auto;
        height: 100%;

        .sideBarTabsMain {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          align-items: center;
          cursor: pointer;
          position: relative;
          gap: 0px;

          .sideBarTabsIcons {
            text-align: center;
            position: relative;

            button {
              padding: 0px;
            }

            .tabName {
              display: none;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .rightSideBox {
    width: 100%;
    // height: calc(100% - 90px);
    height: calc(100% - 64px);
    flex-grow: 1;
    // New
    // padding-bottom: 50px;
  }
}

.sidebar-icon {
  width: 34px;
  height: 34px;
}

.sidebar-icon.home.inActive {
  opacity: 0.5;
}

.topHeader {
  background-color: $dosenNavyBlue;
  height: 84px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  box-sizing: border-box;

  .searchBox {
    border: 1px solid $gray1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 468px;
    height: 40px;
    padding: 9px;
  }
}

/** Container **/
.containerBox {
  height: $containerHeight;
  display: flex;
  overflow: hidden;

  .containerBoxLeft {
    min-width: 100%;
    width: 100%;
    background-color: $white;
    height: 100%;
    border-right: 1px solid #eff0f4;
  }

  .containerBoxRight {
    flex: 1;
    margin: 10px;
    overflow-y: auto;
  }
}

/** Mentee Home */
.mygoalBox {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 10px;
  align-items: center;
}

.MylevelsCardMain {
  display: flex;
  gap: 10px;
  padding: 10px 0;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  .MylevelsCard {
    width: 100%;
    min-width: 90%;
    max-width: 90%;
    min-height: 290px;
    border: 1px solid #eff0f4;
    border-radius: 8px;
    background: #fff;
    scroll-snap-align: center;
  }
}

/** Avatar or style */
.imageStyle {
  border-radius: 50%;
  object-fit: cover;
}

/** Extra */

.MuiDialogActions-root button {
  height: 50px;
}

.Edit-Delete-icons {
  width: 18px;
  height: 18px;
}

.Edit-Delete-icons-task {
  width: 18px;
  height: 18px;
  // position: fixed;
}

/*** Mentee dashboard */
.CardBox {
  background: #ffffff;
  border: 1px solid #eff0f4;
  border-radius: 8px;
}

// only border
.boxBorder {
  border: 1px solid #eff0f4;
  border-radius: 8px;
}

.menteecardBox {
  display: flex;
}

.menteeAddLevelBox {
  align-items: center;
  display: flex;
  margin-left: 8px;
  color: #0071a9;
  cursor: pointer;
  font-family: Open Sans;
  font-weight: 700;
  font-size: 12px;
}

.TaskName {
  color: #abb5be !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.TaskName-edit {
  color: #152536 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.MyGoalLevelCard {
  background: #ffffff;
  border: 1px solid #eff0f4;
  min-height: 290px;
  border-radius: 8px;
}

.MyGoalLevelCard .active {
  border-radius: 8px 8px 0 0;
  border-top: 10px solid #0071a9;
}

.MyGoalLevelCard .inActive {
  border-radius: 8px 8px 0 0;
  border-top: 10px solid #abb5be;
}

/** My mentor card */
.mentorsCardSection {
  width: 100%;
  padding: 20px 10px 10px 10px;
  overflow-y: hidden !important;
  overflow-x: hidden;
  // scrollbar-width: none;

  &:hover {
    overflow-x: auto;
  }

  // &::-webkit-scrollbar {
  //   width: 0 !important;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: transparent;
  // }
}

/**/
.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #eff0f4 !important;
}

/** Chat with me & Book A Call btn &  */
.bookCallBtn {
  border-radius: 29px !important;
  border: 1px solid #0071a9 !important;
  color: #0071a9 !important;
  background: #ffffff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 8px 14px !important;
  line-height: 16px !important;
  display: flex;
  gap: 8px;
}

.bookCallBtn:hover {
  border: 1px solid #0071a9 !important;
  color: #0071a9 !important;
}

.requestMatchBtn {
  border-radius: 29px !important;
  border: 1px solid #0071a9 !important;
  color: #ffffff !important;
  background: #0071a9 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 14px !important;
  line-height: 16px !important;
  box-shadow: none !important;
  display: flex;
  gap: 8px;
}

.lineHeight-none {
  line-height: 0 !important;
}

/** */
.LikeCount {
  line-height: 16px;
}

.color-green {
  color: #28a745 !important;
}

.imglogochat {
  width: 15% !important;
}

.inputChatBoot:focus {
  outline: none;
}

.button__container {
  display: table-caption;
  padding-left: 34px;
  padding-bottom: 10px;
}

/*** Text after 2 line dot dot */
.noWrap-twoLine {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;

  // display: box !important;
  // line-clamp: 2 !important;
  // box-orient: vertical !important;
}

.profileImageBox {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background: #eff0f4;
  position: relative;
  cursor: pointer;
}

.ProfileCameraBgOverlay {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  position: absolute;
  background-color: #00000066;
}

.EdirProfileCameraIcon,
.ProfileCameraBgOverlay img,
.ProfileCameraBgOverlay .CameraIcon,
.AddContentCameraIcon {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
}

.profileImageBox .IfNoIcon {
  width: 26px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.profileImage {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}

/**  */
.img-input-profile-page {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.miniText {
  font-size: 10px !important;
  font-weight: 600 !important;
}

/*** Mentee dashboard */

.Profile-video-container {
  position: relative;
  width: 560px;
  height: 310px;
  cursor: pointer;
  border-radius: 8px;
  /* Use aspect-ratio for modern browsers */
  aspect-ratio: 1.81;

  /* Padding hack for older browsers (Edge <88, Safari <15) */
  //  padding-top: calc(100% / 1.81); /* Maintain 1.81 aspect ratio */
}

// .ChatProfile-video-container::before {
//   content: '';
//   display: block;
//   padding-top: calc(100% / 1.81); /* Maintain aspect ratio */
// }

.ChatProfile-video-container {
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  aspect-ratio: 1.81;
}

.CommunityUservideoArea {
  position: relative;
  width: 100%;
  height: 190px;
  cursor: pointer;
  aspect-ratio: 1.81;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}

.Profile-video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

/** Dialog */
.DialogClose {
  float: right;
  cursor: pointer;

  svg {
    color: #152536;
  }
}

.chatProfileDigital_intro {
  padding: 0;
  margin: 0;
  width: 560px;
  height: 310px;
  cursor: pointer;
  border-radius: 8px;

  .video {
    object-fit: contain;
  }
}

.LibraryVideoAndImage {
  border: 0;
  width: 100%;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  object-fit: cover;
  aspect-ratio: 1.81;
  margin-left: auto;
  margin-right: auto;
}

.LibrarycoverImage {
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  padding-bottom: '5px';
}

/** */
.CardRightTopText {
  color: #1aa179 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: right;
}

.NoWorkingIconBG {
  width: 49px;
  height: 49px;
  min-width: 49px;
  background: #eff0f4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/** Chat Profile */
.chatProfileBox,
.GroupDetailsBox {
  border: 1px solid #152536;
  margin-left: 20px;
  background: #ffffff;
  flex-grow: 1;
  border-radius: 8px;
}

.chatMainBody {
  height: calc(100vh - 192px);
  overflow-y: auto;
  padding: 20px;
}

.otherProfileMainBody {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  height: calc(100vh - 331px);
}

.ChatProfileSocialHandal {
  width: calc(100% - 48px);
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.ProgramUserDetailsBox {
  height: calc(100vh - 260px);
  overflow-x: auto;
}

.PublicProfileEdit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.outerLine-Badge {
  padding: 4px 12px;
  border-radius: 30px;
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #68717a;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 400;
}

/** Button */
.ChatNowButton {
  height: 50px;
}

.ChatNowButton {
  width: 162px;
}

/** Filter Row */
.FilterOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

/*** Popover dropdown **/
.PopoverListStyle {
  gap: 8px;
  display: flex;
  padding: 10px 20px;
  align-items: center;
}

.PopoverListStyle:hover {
  cursor: pointer;
  background-color: #f9fafc;
}

/** Check In With Mentor Dialog */
.cardTagSection {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cardButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

/** Filter Popup */
.filterAccordionSummary .Mui-expanded {
  margin: 0 !important;
}

.clickable,
.pointer,
.cursor-pointer {
  cursor: pointer !important;
}

.clickableNone,
.default,
.cursor-default {
  cursor: default !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.RadioGroupBox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.RadioGroupBox label {
  border: 1px solid #dee2e6;
  border-radius: 16px;
  padding: 5px 20px;
  margin: 0;
}

/** Check Btn Chip */

.CheckBtnChip {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 12px 20px;
  cursor: pointer;

  svg {
    color: #abb5be;
  }

  p {
    color: $primary;
  }
}

.CheckBtnChip.checked {
  background-color: #6c757d;
  border: 1px solid #6c757d;

  svg {
    color: #ffffff;
  }

  p {
    color: #ffffff;
  }
}

/** Calendar page */
.CallsPageTopOptions {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.NewCallAddIcon {
  background-color: #f3f3f3;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.BookingRequestDot,
.UpcomingBookingDot,
.PastBookingDot,
.RejectedBookingDot {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 48%;

  p {
    font-size: 10px;
  }
}

.BookingRequestDot svg {
  color: #0071a9;
}

.UpcomingBookingDot svg {
  color: #e99940;
}

.PastBookingDot svg {
  color: #abb5be;
}

.RejectedBookingDot svg {
  color: #dc3545;
}

.CallsTableBox {
  background: #eff0f4;
  border-radius: 8px;
  margin: 0 20px;
  padding: 20px 0;
  margin-bottom: 20px;
}

.CallsTableRow {
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
}

.CallsAttendeesImages {
  display: flex;

  img {
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    border-radius: 8px;
    object-fit: cover;
  }
}

.CallsAttendeesImages img.Image2,
.CallsAttendeesImages img.Image3 {
  margin-left: -10px;
}

.CallsTableBtnCell {
  display: flex;
  align-items: center;
  gap: 8px;

  .CallsPageBtn {
    width: 50%;
    height: 50px;
  }
}

.ManualMatchIcon {
  display: flex;
  justify-content: center;
}

.ManualMatchtextBox {
  margin-top: -13px;
}

.lineDotted {
  width: 100%;
  display: flex;
  border-bottom: 2px dotted #abb5be;
}

.ChevronLeftIconLeft,
.ChevronLeftIconRight {
  color: #abb5be !important;
}

.ChevronLeftIconLeft {
  margin-right: -5px;
}

.ChevronLeftIconRight {
  margin-left: -5px;
}

.twoLineThenDots {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  // display: box !important;
  // line-clamp: 2 !important;
  // box-orient: vertical !important;

  h6 {
    // white-space: break-spaces;
    white-space: pre-line;
  }
}

/** Article / Content **/
.ArticleBodyContent {
  font-size: 14px !important;
  font-family: Open Sans !important;
  line-height: 21px;

  blockquote {
    padding-left: 10px;
    border-left: solid 3px #abb5be;
    line-height: 21px;
  }

  p,
  blockquote {
    font-family: Open Sans !important;
    line-height: 21px;
    font-size: 14px !important;

    span,
    strong {
      font-family: Open Sans !important;
      font-size: 14px !important;
      line-height: 21px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Open Sans !important;
    font-size: 14px !important;
    color: $primary;
    line-height: 21px;
  }

  code {
    strong {
      font-family: monospace !important;
    }

    font-family: monospace !important;
    color: $primary;
    font-size: 14px !important;
  }

  iframe {
    border: 0;
    width: 100%;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    object-fit: cover;
    aspect-ratio: 1.81;
    margin-left: auto;
    margin-right: auto;
  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }
}

.ArticleBodyContent {
  p,
  blockquote {
    margin-bottom: 16px !important;
  }

  img {
    max-height: 100% !important;
    max-width: 100% !important;
    margin: 20px auto;
    display: flex;
  }
}

iframe.LibaryEmbeddedDocument {
  border: 0;
  height: 315px;
  width: 560px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.ProfileDetailsBack {
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 10px;
  }

  p {
    color: #152536;
    font-weight: 700;
  }
}

.selectedSectionsClass {

  .Mui-selected,
  .Mui-selected.dayClass {
    color: #ffffff !important;
    background-color: #0071a9 !important;
  }
}

/** Side bar */
.listGroup {
  padding: 20px 16px;
}

.listProfileSidebarActive {
  background: #0071a9 !important;
  color: #ffffff;
  border-radius: 8px;
}

.listProfileSidebar {
  border: none !important;
  font-family: 'Open Sans';
  color: #152536;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  border-radius: 8px;
  padding: 13px !important;
  cursor: pointer;
}

.listProfileSidebar:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

/** Table loader */
.last-cell-table-Loader {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  -webkit-user-select: none;
  /* For Safari support */
  user-select: none;
  vertical-align: middle;
}

/** Help table */
.contentimg {
  padding: 0px;
  margin: 0px 10px;
  min-width: 70px;
  width: 70px;
  height: 47px;
  border-radius: 5px;
  object-fit: cover;
}

.acceptBtn {
  height: 24px;
  width: 55px;
  font-size: 12px !important;
}

/** Typing */
.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #df6438;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

.radioAlign {
  align-items: flex-start !important;

  span.MuiButtonBase-root {
    padding: 0;
    align-items: baseline;
  }
}


.image-block {
  position: relative;
  // margin: 1rem 0;
  display: inline-block;
}

.image-container {
  position: relative;
  display: inline-block;
}

.iframe-container {
  position: relative;
  display: inline-block;
}

.resizable-image {
  display: block;
  max-width: 100%;
  height: auto;
  transition: all 0.2s ease;
}

.resize-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: rgba(200, 200, 200, 0.8);
  cursor: nwse-resize;
  border-radius: 0 0 2px 0;
  transition: background-color 0.2s ease;
}

.resize-handle:hover {
  background-color: rgba(180, 180, 180, 0.9);
}

.resize-handle:active {
  background-color: rgba(160, 160, 160, 1);
}

.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  background-color: #262738B3;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1;
  transition: all 0.2s ease;
  opacity: 0;
}

.image-container:hover .delete-button,
.iframe-container:hover .delete-button {
  opacity: 1;
}

.delete-button:hover {
  background-color: #262738B3;
  transform: scale(1.1);
}

.delete-button:active {
  transform: scale(0.95);
}

.embedded-iframe {
  width: 300px;
  height: 300px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
}

/* Resizing states */
.image-block.resizing {
  -webkit-user-select: none;
  user-select: none;
}

.image-block.resizing .resizable-image {
  pointer-events: none;
}

/* Loading state */
.image-block.loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .resize-handle {
    width: 24px;
    height: 24px;
  }

  .delete-button {
    opacity: 1;
  }
}

/* Focus styles for accessibility */
.delete-button:focus {
  outline: 2px solid #3b82f6;
  outline-offset: 2px;
}

.resize-handle:focus {
  outline: 2px solid #3b82f6;
  outline-offset: 2px;
}

/* Animation for resize handle */
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.resize-handle:hover {
  animation: pulse 1s infinite;
}

/** Responsive section */

/** Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .cardButton {
    margin-left: 58px;
  }
}

/** Medium devices (tablets, 768px and up) */
@media (min-width: 600px) {
  // .bodyBox {
  //   padding: 20px;
  // }

  .topHeader {
    background-color: $white;
    border-bottom: 1px solid #eff0f4;
  }

  .ProfileDetailsBack {
    display: none;
  }

  .d-desktop {
    display: block !important;
  }

  .d-mobile {
    display: none !important;
  }

  .mainBody {
    flex-direction: row;

    .LeftSideNav {
      min-width: 110px;
      max-width: 110px;
      height: 100%;

      .sideBarMain {
        .logoArea {
          display: block !important;
        }

        .sideBarTabsBox {
          overflow-x: hidden;
          height: calc(100% - 190px);

          .sideBarTabsMain {
            flex-direction: column;
            gap: 32px;

            .sideBarTabsIcons {
              height: 53px;

              .tabName {
                display: block;
              }
            }
          }
        }
      }
    }

    .rightSideBox {
      width: calc(100% - 110px);
      height: 100%;
    }
  }

  /** Container **/
  .containerBox {
    display: flex;

    .containerBoxLeft {
      width: $leftBar;
      min-width: $leftBar;
      border-right: 1px solid #eff0f4;
    }

    .containerBoxRight {
      flex: 1;
      margin: 20px;
    }
  }

  /** Calendar page */
  .CallsPageTopOptions {
    flex-wrap: nowrap;
    gap: 20px;
  }

  .BookingRequestDot,
  .UpcomingBookingDot,
  .PastBookingDot,
  .RejectedBookingDot {
    display: flex;
    align-items: center;
    gap: 8px;
    width: auto;

    p {
      font-size: 14px;
    }
  }

  /** Mentee Home */
  .mygoalBox {
    gap: 20px;
    padding: 20px;
    flex-direction: row;
  }

  .MylevelsCardMain {
    gap: 20px;
    padding: 20px 0;

    .MylevelsCard {
      width: 480px;
      min-width: 480px;
      max-width: 480px;
    }
  }

  .CommunityUservideoArea {
    width: 560px;
    height: 325px;
  }

}

/** Large devices (desktops, 992px and up) */
@media (min-width: 900px) {}

/** X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .ArticleBodyContent {
    iframe {
      height: 315px;
      width: 560px;
    }
  }

  .LibraryVideoAndImage {
    height: 315px;
    width: 560px;
  }
}

/** XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1563px) {}