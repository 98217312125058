#wrapper {
    margin: 0 auto;
    display: block;
    width: 960px;
  }
  .page-header {
    text-align: center;
    font-size: 1.5em;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
    margin: 30px 0
  }
  #pagination {
    margin: 0;
    padding: 0;
    text-align: center
  }
  #pagination li {
    display: inline
  }
  #pagination li a {
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
    color: #000
  }
  
  /* Active and Hoverable Pagination */
  #pagination li a {
    border-radius: 5px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s
      
  }
  #pagination li a.active {
    background-color: #0071A9;
    color: #fff
  }
  #pagination li a:hover:not(.active) {
    background-color: #ddd;
  } 
  
  /* border-pagination */
  .b-pagination-outer {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    display: flex
  }
  #border-pagination {
    margin: 0 auto;
    padding: 0;
    text-align: center
  }
  #border-pagination li {
    display: inline;
  
  }
  #border-pagination li a {
    display: block;
    text-decoration: none;
    color: #000;
    padding: 5px 10px;
    border: 1px solid #ddd;
    float: left;
    border-radius: 9px;
  }
  #border-pagination li a {
    -webkit-transition: background-color 0.4s;
    transition: background-color 0.4s
  }
  #border-pagination li a.active {
    border: 1px solid #0071A9;
  }
  #border-pagination li a:hover:not(.active) {
    background: #ddd;
  }

  .dots{
    border: none !important;
  }

  .dots:hover:not(.active) {
    background: none !important;
  }