.chat-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  top: 0px;
  left: 0px;
}

.chat-wrap {
  max-width: 380px;
  height: 100%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 0;
}

.chat-message-wrap {
  flex-grow: 1;
  overflow-x: auto;
  border-bottom: 1px solid #eee;
  padding: 20px;
  padding-bottom: 0;
  // margin: 0 10px;
}

.chat-box{
  margin: 10px;
  border: 1px solid #d9d9d9;
  border-radius:8px;
  padding: 10px;
}

.chat-message-box {
  height: 75px;
  box-sizing: border-box;
  // padding: 10px 20px 20px;
  padding-top: 10px;
  position: relative;   
}

.chat-message-box textarea {
  padding-right: 30px !important;
  padding: 0px;
  height: 100%;
  width: 100%;
 // border-radius: 8px;
  border:none;
  resize: none;
}

.chat-message-box textarea:focus{
  border-color: transparent;
  box-shadow: none !important;
  border-right-width: 0px;
  outline: 0;
}

.send-message-button{
  color: #0071A9;
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
}

.chat-disabled {
  height: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  flex-shrink: 0;
}

.chatHeader {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  margin-left: 10px;
}

.VideoChatHeader{
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  margin-right: 15px;
  cursor: pointer;
}

.chatIcon {
  width: 50px
}

.iconBtn {
  border-radius: 42px;
  cursor: pointer;
}

//New ChatBox

.videocall-chat-mainbox{
  background-color: #fff;
  border-radius: 8px;
  width: 380px;
  min-width:380px;
  max-width:380px;
}
.videocall-chat-header{
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 20px;
}