@import './variables.scss';

/** Margin auto */
.m-auto {
    margin: auto !important
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mt-auto {
    margin-top: auto !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ml-auto {
    margin-left: auto !important;
}

.mr-auto {
    margin-right: auto !important;
}

/* Padding & margin */
@each $value in $spacing-values {

    /** Margin */
    .m-#{$value} {
        margin: #{$value}px !important;
    }

    .mx-#{$value} {
        margin-left: #{$value}px !important;
        margin-right: #{$value}px !important;
    }

    .my-#{$value} {
        margin-top: #{$value}px !important;
        margin-bottom: #{$value}px !important;
    }

    .mt-#{$value} {
        margin-top: #{$value}px !important;
    }

    .mb-#{$value} {
        margin-bottom: #{$value}px !important;
    }

    .ml-#{$value} {
        margin-left: #{$value}px !important;
    }

    .mr-#{$value} {
        margin-right: #{$value}px !important;
    }

    /** Padding */
    .p-#{$value} {
        padding: #{$value}px !important;
    }

    .px-#{$value} {
        padding-left: #{$value}px !important;
        padding-right: #{$value}px !important;
    }

    .py-#{$value} {
        padding-top: #{$value}px !important;
        padding-bottom: #{$value}px !important;
    }

    .pt-#{$value} {
        padding-top: #{$value}px !important;
    }

    .pb-#{$value} {
        padding-bottom: #{$value}px !important;
    }

    .pl-#{$value} {
        padding-left: #{$value}px !important;
    }

    .pr-#{$value} {
        padding-right: #{$value}px !important;
    }
}

/** Top */
.top--25 {
    top: -25px !important;
}

.gap-10 {
    gap: 10px !important;
}